import React from 'react';
import styled, { css } from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

import RichText from '../atoms/RichText';
import Heading from '../atoms/Heading';

const cellStyle = css`
  position: relative;
  flex: 1 1 auto;
  border-bottom: 1px solid ${props => props.theme.color.borderLighter};
  width: 100%;

  ${media.sm`
    width: ${props => 100 / props.columns}%;
  `}
`;

const Table = styled.div`
  flex-shrink: 0;
  border: 1px solid ${props => props.theme.color.borderLight};
  border-radius: ${props => props.theme.radius.large};
  overflow: hidden;
`;

const CellsWrapper = styled.div`
  position: relative;
  top: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-top: -1px;
`;

const Cell = styled.div`
  ${cellStyle}

  padding: ${scale(0.5)} ${scale(1)};

  ${media.xsOnly`
    ${props =>
      props.firstOfRow &&
      `
      padding-top: ${scale(1)};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        flex: 1 1 auto;
        border-bottom: 1px solid ${props.theme.color.borderLighter};
        height: ${scale(0.5)};
        width: 100%;
        background: ${props.theme.color.grayLight};
      }

      &:nth-child(${props.headerTable ? props.columns + 1 : 1}) {
        padding-top: ${scale(0.5)}

        &::before {
          display: none;
        }
      }
    `}
  `}
`;

const HeaderCell = styled.div`
  ${cellStyle}

  display: none;
  padding: ${scale(0.365)} ${scale(1)} ${scale(0.125)};
  background: ${props => props.theme.color.grayLight};

  ${media.sm`
    display: flex;
  `}
`;

export default props => {
  const { header, content, children } = props;

  return (
    <Table {...props}>
      <CellsWrapper>
        {header &&
          header.map((cell, index) => (
            /* eslint-disable react/no-array-index-key */
            <HeaderCell columns={props.columns} key={`header-${index}`}>
              <Heading headingSize={3} as="p">
                {cell}
              </Heading>
            </HeaderCell>
            /* eslint-enable react/no-array-index-key */
          ))}

        {content &&
          content.map((row, rowIndex) =>
            row.map((cell, cellIndex) => {
              const cellContent = cell.startsWith('<p>')
                ? cell
                : `<p>${cell}</p>`;

              return (
                /* eslint-disable react/no-array-index-key */
                <Cell
                  headerTable={header}
                  firstOfRow={cellIndex === 0}
                  columns={props.columns}
                  key={`cell-${rowIndex}-${cellIndex}`}
                >
                  <RichText htmlContent={cellContent} />
                </Cell>
                /* eslint-enable react/no-array-index-key */
              );
            })
          )}

        {children}
      </CellsWrapper>
    </Table>
  );
};
