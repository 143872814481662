import React from 'react';
import styled from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

const Sidebar = styled.aside`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.border};
  padding: ${scale(0.5)} ${scale(1.5)};
  width: 100%;
  height: ${props => scale(props.theme.height.sidebarMobile)};
  background: ${props => props.theme.color.bg};

  ${media.md`
    position: fixed;
    left: 1px;
    top: ${props =>
      scale(
        props.theme.height.chromeBarTop + props.theme.height.breadcrumbsBar
      )};
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border: 0;
    height: calc(100vh - ${props =>
      scale(
        props.theme.height.chromeBarTop +
          props.theme.height.breadcrumbsBar +
          props.theme.height.chromeBarBottom
      )});
    width: ${props => scale(props.theme.width.sidebar)};
    pointer-events: none;
  `}
`;

export default ({ children }) => <Sidebar>{children}</Sidebar>;
