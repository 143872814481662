const formatMoney = (
  value,
  decimalLength,
  sectionLength,
  sectionDelimiter,
  decimalDelimiter
) => {
  const re = `\\d(?=(\\d{${sectionLength || 3}})+${
    decimalLength > 0 ? '\\D' : '$'
  })`;

  const num = value.toFixed(Math.max(0, ~~decimalLength)); // eslint-disable-line no-bitwise

  return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(
    new RegExp(re, 'g'),
    `$&${sectionDelimiter || ','}`
  );
};

export default value => `${formatMoney(value, 2, 3, '.', ',')} €`;
