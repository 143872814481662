import styled from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

export default styled.main.attrs({ id: 'scrollContainer' })`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${props => props.theme.color.bg};
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${props =>
    props.withSidebar &&
    `
    padding: ${scale(0.5)} ${scale(1.5)};
  `}

  ${props =>
    props.withBorders &&
    `
    border: 1px solid ${props.theme.color.border};
    border-width: 0 1px;
  `}

  ${media.md`
    ${props =>
      props.withSidebar &&
      `
      padding-left: ${scale(props.theme.width.sidebar + 1.5)};
      padding-right: ${scale(props.theme.width.sidebar + 1.5)};
    `}
  `}
`;
