import React from 'react';
import styled from 'styled-components';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Heading from '../atoms/Heading';

const Info = styled.dl`
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;

  ${media.md`
    flex: 0 0 auto;
    padding-left: ${scale(1.5)};
    padding-bottom: ${scale(1.5)};
  `}
`;

const Label = styled(Heading)`
  && {
    padding: ${scale(0.5)} 0 0;
  }
`;

const Content = styled.dd`
  font-size: 11px;
  line-height: 14px;
  font-family: ${props => props.theme.font.extended};
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default ({ data }) => (
  <Info>
    {data.map(item => (
      <React.Fragment key={item.label.toLowerCase()}>
        <Label headingSize={3} use="dt">
          {item.label}
        </Label>
        <Content>{item.content}</Content>
      </React.Fragment>
    ))}
  </Info>
);
