import React from 'react';
import { Query } from 'react-apollo';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

import CartContext from '../context/CartContext';

import { GET_CHECKOUT } from '../data/checkout';

import CartTable from '../modules/CartTable';

import Button from '../atoms/Button';
import Text from '../atoms/Text';
import Heading from '../atoms/Heading';
import IconLink from '../atoms/IconLink';

import HeadOverride from '../components/HeadOverride';
import SidebarActions from '../components/SidebarActions';
import ContentWrapper from '../components/ContentWrapper';
import InfoItemsBlock from '../components/InfoItemsBlock';
import SidebarLayout from '../components/SidebarLayout';
import Sidebar from '../components/Sidebar';
import SidebarInfo from '../components/SidebarInfo';
import BreadcrumbsBar from '../components/BreadcrumbsBar';

const isBrowser = typeof window !== 'undefined';

const CartBottom = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-top: ${scale(3)};
  padding-bottom: ${scale(2)};

  ${media.sm`
    margin-top: ${scale(4)};
  `}
`;

const CartInformation = styled.div`
  margin-top: ${scale(4)};

  ${media.sm`
    order: -1;
    margin-top: 0;
    width: 50%;
  `}
`;

const CheckoutButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${media.sm`
    width: 50%;
  `}
`;

const Cart = ({ pageData, pageContext }) => {
  const cartInformation = pageData.settings.edges[0].node.data;

  return (
    <>
      <HeadOverride title="Cart" />

      <BreadcrumbsBar breadcrumbs={pageContext.slugs} />

      <SidebarLayout withBorders>
        <CartContext.Consumer>
          {({ checkoutId }) =>
            checkoutId && (
              <Query
                query={GET_CHECKOUT}
                variables={{ checkoutId }}
                partialRefetch
              >
                {({ loading, data }) => {
                  let checkoutUrl = null;
                  let qtyInCart = 0;

                  if (!loading && data) {
                    checkoutUrl = data.node.webUrl.replace(
                      'gmbh-official.myshopify.com',
                      'shop.gmbhgmbh.eu'
                    );

                    qtyInCart = data.node.lineItems.edges.reduce(
                      (acc, { node }) => acc + node.quantity,
                      0
                    );
                  }

                  return (
                    <>
                      <Sidebar>
                        <SidebarActions>
                          {isBrowser ? (
                            <Button
                              icon="back"
                              onClick={() => isBrowser && window.history.back()}
                              secondary
                            />
                          ) : (
                            <Button
                              icon="back"
                              useLink
                              link="/shop/"
                              secondary
                            />
                          )}
                        </SidebarActions>

                        <SidebarInfo
                          data={[
                            {
                              label: 'Inhalt',
                              content: `${qtyInCart} ${
                                qtyInCart !== 1 ? 'items' : 'item'
                              }`,
                            },
                          ]}
                        />
                      </Sidebar>

                      <ContentWrapper withSidebar>
                        <Heading headingSize={1} visuallyHidden>
                          Cart
                        </Heading>

                        {!loading && (
                          <CartTable
                            fullCheckoutData={data}
                            lineItems={data.node.lineItems.edges}
                            totalPrice={data.node.totalPrice}
                            totalTax={data.node.totalTax}
                          />
                        )}

                        <CartBottom>
                          <CheckoutButtonHolder>
                            {checkoutUrl ? (
                              <Button
                                disabled={qtyInCart === 0}
                                href={checkoutUrl}
                                label="Go to checkout"
                              />
                            ) : (
                              <Button disabled label="Go to checkout" />
                            )}
                          </CheckoutButtonHolder>

                          <CartInformation>
                            <InfoItemsBlock
                              title={cartInformation.label_cart_1}
                            >
                              <Text>
                                {cartInformation.content_cart_1}{' '}
                                <IconLink
                                  to="/shipping-returns"
                                  type="infoInverted"
                                />
                              </Text>
                            </InfoItemsBlock>

                            <InfoItemsBlock
                              title={cartInformation.label_cart_2}
                            >
                              <Text>{cartInformation.content_cart_2}</Text>
                            </InfoItemsBlock>
                          </CartInformation>
                        </CartBottom>
                      </ContentWrapper>
                    </>
                  );
                }}
              </Query>
            )
          }
        </CartContext.Consumer>
      </SidebarLayout>
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        settings: allPrismicSiteSettings {
          edges {
            node {
              data {
                label_cart_1
                label_cart_2
                content_cart_1
                content_cart_2
              }
            }
          }
        }
      }
    `}
    render={data => <Cart pageData={data} {...props} />}
  />
);
