import React from 'react';
import styled from 'styled-components';

import scale from '../utils/scale';

import Heading from '../atoms/Heading';

const InfoItemsBlock = styled.div`
  margin-top: ${scale(4)};

  :first-child {
    margin-top: 0;
  }
`;

export default ({ children, title, ...props }) => (
  <InfoItemsBlock {...props}>
    {typeof title !== 'undefined' && (
      <Heading headingSize={3} as="h3">
        {title}
      </Heading>
    )}

    {children}
  </InfoItemsBlock>
);
