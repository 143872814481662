import React from 'react';

import Header from './Header';
import LineItem from './LineItem';
import TableContentEmtpy from './TableContentEmtpy';
import Totals from './Totals';

import Table from '../../components/Table';

export default ({ lineItems, totalPrice, totalTax, fullCheckoutData }) => {
  const tableColumns = [
    {
      name: 'product',
      label: 'Produkt',
      size: [1, 8 / 20, 8 / 20, 8 / 20],
    },
    {
      name: 'size',
      label: 'Größe/Size',
      size: [1, 1.5 / 20, 1.5 / 20, 2.5 / 20],
    },
    {
      name: 'qty',
      label: 'Menge',
      size: [1 / 2, 4 / 20, 2.5 / 20, 2.5 / 20],
    },
    {
      name: 'del',
      label: '',
      size: [0, 1.5 / 20],
    },
    {
      name: 'price',
      label: 'Einzelpreis',
      size: [0, 0, 0, 2.5 / 20],
    },
    {
      name: 'total',
      label: 'Gesamtpreis',
      size: [1 / 2, 3 / 20],
    },
    {
      name: 'del-mobile',
      label: '',
      size: [1, 0],
    },
  ];

  return (
    <Table>
      <Header columns={tableColumns} />

      {lineItems.length ? (
        lineItems.map(({ node }) => (
          <LineItem
            key={node.id}
            data={node}
            columns={tableColumns}
            fullCheckoutData={fullCheckoutData}
          />
        ))
      ) : (
        <TableContentEmtpy />
      )}

      <Totals totalPrice={totalPrice} totalTax={totalTax} />
    </Table>
  );
};
