import React from 'react';
import styled from 'styled-components';

import Row from './Row';
import Cell from './Cell';
import QuantityControl from './QuantityControl';
import DeleteControl from './DeleteControl';

import Text from '../../atoms/Text';

import scale from '../../utils/scale';
import media from '../../utils/mediaQueries';
import formatPrice from '../../utils/formatPrice';

const ProductImage = styled.img`
  width: 56px;
  height: auto;
  margin-right: ${scale(2)};
  margin-top: -${scale(0.5)};
  margin-bottom: -${scale(0.5)};

  ${media.md`
    margin-top: -${scale(1.5)};
    margin-bottom: -${scale(1.5)};
  `}
`;

const Price = styled.span`
  display: block;
  text-align: right;

  ${props =>
    props.old &&
    `
    text-decoration: line-through;
    opacity: 0.4;
  `}
`;

const BoldText = styled(Text).attrs({
  extended: true,
  bold: true,
})`
  margin-top: 2px;
`;

export default ({ data, columns, fullCheckoutData }) => {
  const itemPrice = parseFloat(data.variant.price);
  const totalPrice = data.quantity * itemPrice;

  const size = data.variant.selectedOptions[0].value;

  const priceInfo = data.variant.compareAtPrice ? (
    <>
      <Price old>{formatPrice(parseFloat(data.variant.compareAtPrice))}</Price>
      <Price>{formatPrice(itemPrice)}</Price>
    </>
  ) : (
    formatPrice(itemPrice)
  );

  return (
    <Row>
      <Cell w={columns[0].size} name={columns[0].name}>
        <ProductImage
          src={data.variant.image.transformedSrc}
          alt={data.title}
        />

        <BoldText>{data.title}</BoldText>
      </Cell>

      <Cell w={columns[1].size} name={columns[1].name}>
        <Text>{size !== 'Default Title' && size}</Text>
      </Cell>

      <Cell w={columns[2].size} name={columns[2].name}>
        <QuantityControl
          id={data.id}
          quantity={data.quantity}
          fullCheckoutData={fullCheckoutData}
        />
      </Cell>

      <Cell w={columns[3].size} name={columns[3].name}>
        <DeleteControl id={data.id} fullCheckoutData={fullCheckoutData} />
      </Cell>

      <Cell w={columns[4].size} name={columns[4].name}>
        <Text>{priceInfo}</Text>
      </Cell>

      <Cell w={columns[5].size} name={columns[5].name}>
        <BoldText>{formatPrice(totalPrice)}</BoldText>
      </Cell>

      <Cell w={columns[6].size} name={columns[6].name}>
        <DeleteControl id={data.id} fullCheckoutData={fullCheckoutData} />
      </Cell>
    </Row>
  );
};
