import styled from 'styled-components';

import media from '../../utils/mediaQueries';
import scale from '../../utils/scale';

export default styled.div.attrs(({ w }) => ({
  w: w
    ? w.map(width => (width ? `${width * 100}%` : 'auto'))
    : ['auto', 'auto', 'auto', 'auto'],
  disp: w
    ? w.map(width => (width === 0 ? 'none' : 'flex'))
    : ['flex', 'flex', 'flex', 'flex'],
}))`
  flex: 1 1 auto;
  display: ${props => props.disp[0]};
  padding: ${scale(1.5)} ${scale(1)};
  width: ${props => props.w[0]};
  border-bottom: 1px solid ${props => props.theme.color.borderLighter};
  border-right: ${props =>
    props.name === 'qty'
      ? `1px solid ${props.theme.color.borderLighter}`
      : 'none'};
  justify-content: center;
  align-items: center;

  ${media.sm`
    display: ${props => props.disp[1]};
    padding-top: ${scale(2)};
    padding-bottom: ${scale(2)};
    width: ${props => props.w[1]};
    border-right: none;
    justify-content: flex-start;
  `}

  ${media.md`
    display: ${props => props.disp[2]};
    width: ${props => props.w[2]};
  `}

  ${media.lg`
    display: ${props => props.disp[3]};
    width: ${props => props.w[3]};
  `}

  p {
    width: 100%;
    text-align: ${props =>
      ['product'].includes(props.name) ? 'left' : 'center'};

    ${media.sm`
      text-align: ${props =>
        ['price', 'total'].includes(props.name) ? 'right' : 'left'};
    `}
  }
`;
