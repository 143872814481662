import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Text from '../atoms/Text';
import Button from '../atoms/Button';

const Bar = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.color.border};
  padding: 0 ${scale(1.5)};
  height: ${props => scale(props.theme.height.breadcrumbsBarMobile)};
  background: ${props => props.theme.color.gray};

  ${media.md`
    border-top: 0;
    height: ${props => scale(props.theme.height.breadcrumbsBar)};
  `}
`;

const Hint = styled.span`
  display: none;

  ${media.md`
    display: inline;
  `}
`;

const BreadcrumbsHolder = styled.div`
  flex: 1 1 auto;
  padding-right: ${scale(1)};
  overflow: hidden;
`;

const Breadcrumbs = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ul {
    display: inline;

    li {
      display: inline;

      ::after {
        content: ' >> ';
      }

      :last-child::after {
        content: '';
      }

      a {
        color: ${props => props.theme.color.base};
        text-decoration: none;

        :hover,
        :focus {
          text-decoration: underline;
        }
      }
    }
  }
`;

export default ({ breadcrumbs, showHomeLink = true }) => {
  const breadcrumbData = breadcrumbs.map((item, index) => ({
    ...item,
    url: `/${breadcrumbs
      .slice(0, index + 1)
      .map(segment => segment.slug)
      .join('/')}/`,
  }));

  breadcrumbData.unshift({
    slug: 'home',
    breadcrumb: 'GmbH',
    url: '/',
  });

  return (
    <Bar>
      <BreadcrumbsHolder>
        <Breadcrumbs italic use="div">
          <Hint>You are here: </Hint>

          <ul>
            {breadcrumbData.map(item => (
              <li key={item.slug}>
                <Link to={item.url}>{item.breadcrumb}</Link>
              </li>
            ))}
          </ul>
        </Breadcrumbs>
      </BreadcrumbsHolder>

      {showHomeLink && <Button label="Change" useLink link="/" />}
    </Bar>
  );
};
