import React from 'react';
import styled from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

const ActionsWrapper = styled.div`
  pointer-events: all;
`;

const Actions = styled.div`
  flex: 0 0 auto;
  padding-right: ${scale(2)};

  ${media.md`
    flex: 1 1 auto;
    padding: 0;
  `}
`;

export default ({ children }) => (
  <Actions>
    <ActionsWrapper>{children}</ActionsWrapper>
  </Actions>
);
