import React from 'react';
import styled from 'styled-components';

import Row from './Row';
import Cell from './Cell';

import Text from '../../atoms/Text';

import formatPrice from '../../utils/formatPrice';

const Wrapper = styled.div`
  width: 100%;
`;

export default ({ totalPrice, totalTax }) => (
  <Row>
    <Cell name="total">
      <Wrapper>
        <Text bold extended>
          Total: {formatPrice(parseFloat(totalPrice))}
        </Text>
        <Text>incl. VAT: {formatPrice(parseFloat(totalTax))}</Text>
      </Wrapper>
    </Cell>
  </Row>
);
