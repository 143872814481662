import React from 'react';
import styled from 'styled-components';

import Row from './Row';
import Cell from './Cell';

import Text from '../../atoms/Text';

const NoProductsText = styled(Text).attrs({
  extended: true,
  bold: true,
})`
  && {
    color: ${props => props.theme.color.grayMedium};
    text-align: center;
  }
`;

export default () => (
  <Row>
    <Cell name="empty-cart">
      <NoProductsText>No products in cart.</NoProductsText>
    </Cell>
  </Row>
);
