import React from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';

import media from '../../utils/mediaQueries';
import scale from '../../utils/scale';

import CartContext from '../../context/CartContext';

import {
  UPDATE_LINE_ITEM,
  optimisticUpdateLineItem,
} from '../../data/checkout';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

const QuantityButton = styled(Button)`
  && {
    ${media.md`
      margin-top: -2px;
      width: ${scale(3)};
      height: ${scale(3)};
    `}
  }
`;

const QuantityText = styled(Text).attrs({
  extended: true,
  bold: true,
})`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${scale(6)};

    ${media.md`
      width: ${scale(4)};
    `}
  }
`;

export default ({ id, quantity, fullCheckoutData }) => (
  <CartContext.Consumer>
    {({ checkoutId }) => (
      <Mutation mutation={UPDATE_LINE_ITEM}>
        {updateLineItem => (
          <>
            <QuantityButton
              icon="minus"
              disabled={!checkoutId || quantity < 2}
              onClick={() =>
                updateLineItem({
                  variables: {
                    checkoutId,
                    lineItemId: id,
                    lineItemQuantity: quantity - 1,
                  },
                  optimisticResponse: optimisticUpdateLineItem(
                    fullCheckoutData,
                    id,
                    quantity - 1
                  ),
                })
              }
            />

            <QuantityText>{quantity}</QuantityText>

            <QuantityButton
              icon="plus"
              disabled={!checkoutId}
              onClick={() =>
                updateLineItem({
                  variables: {
                    checkoutId,
                    lineItemId: id,
                    lineItemQuantity: quantity + 1,
                  },
                  optimisticResponse: optimisticUpdateLineItem(
                    fullCheckoutData,
                    id,
                    quantity + 1
                  ),
                })
              }
            />
          </>
        )}
      </Mutation>
    )}
  </CartContext.Consumer>
);
