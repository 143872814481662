import React from 'react';

import HeaderCell from './HeaderCell';

import Heading from '../../atoms/Heading';

export default ({ columns }) => (
  <>
    <HeaderCell w={columns[0].size} name={columns[0].name}>
      <Heading headingSize={3} as="p">
        {columns[0].label}
      </Heading>
    </HeaderCell>

    <HeaderCell w={columns[1].size} name={columns[1].name}>
      <Heading headingSize={3} as="p">
        {columns[1].label}
      </Heading>
    </HeaderCell>

    <HeaderCell w={columns[2].size} name={columns[2].name}>
      <Heading headingSize={3} as="p">
        {columns[2].label}
      </Heading>
    </HeaderCell>

    <HeaderCell w={columns[3].size} name={columns[3].name}>
      <Heading headingSize={3} as="p">
        {columns[3].label}
      </Heading>
    </HeaderCell>

    <HeaderCell w={columns[4].size} name={columns[4].name}>
      <Heading headingSize={3} as="p">
        {columns[4].label}
      </Heading>
    </HeaderCell>

    <HeaderCell w={columns[5].size} name={columns[5].name}>
      <Heading headingSize={3} as="p">
        {columns[5].label}
      </Heading>
    </HeaderCell>

    <HeaderCell w={columns[6].size} name={columns[6].name}>
      <Heading headingSize={3} as="p">
        {columns[6].label}
      </Heading>
    </HeaderCell>
  </>
);
