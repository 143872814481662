import React from 'react';
import styled from 'styled-components';

import media from '../utils/mediaQueries';

const Layout = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;

  ${props =>
    props.withBorders &&
    `
    border: 1px solid ${props.theme.color.border};
    border-width: 0 1px;
  `}

  ${media.md`
    flex-direction: row;
  `}
`;

export default props => <Layout {...props}>{props.children}</Layout>;
