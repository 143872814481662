import styled from 'styled-components';

import media from '../../utils/mediaQueries';
import scale from '../../utils/scale';

export default styled.article`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: ${scale(0.5)};

  ${media.sm`
    padding-bottom: 0;
  `};

  ${media.md`
    flex-wrap: nowrap;
  `};

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    flex: 1 1 auto;
    border: 1px solid ${props => props.theme.color.borderLighter};
    border-width: 1px 0;
    height: ${scale(0.5)};
    width: 100%;
    background: ${props => props.theme.color.grayLight};

    ${media.sm`
      display: none;
    `};
  }

  :last-child {
    padding-bottom: 0;

    ::before {
      display: none;
    }
  }
`;
