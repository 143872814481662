import styled from 'styled-components';

import Cell from './Cell';

import media from '../../utils/mediaQueries';
import scale from '../../utils/scale';

export default styled(Cell)`
  padding: ${scale(0.365)} ${scale(1)} ${scale(0.125)};
  background: ${props => props.theme.color.grayLight};

  ${media.xsOnly`
    display: none;
  `}

  ${media.sm`
    padding: ${scale(0.365)} ${scale(1)} ${scale(0.125)};
  `}
`;
