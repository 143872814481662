import React from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';

import CartContext from '../../context/CartContext';

import {
  REMOVE_LINE_ITEM,
  optimisticRemoveLineItem,
} from '../../data/checkout';

import Text from '../../atoms/Text';

const DeleteText = styled(Text).attrs({
  textSize: 'sm',
})`
  a {
    color: ${props => props.theme.color.base};

    &:hover,
    &:focus {
      color: ${props => props.theme.color.red};
    }
  }
`;

export default ({ id, fullCheckoutData }) => (
  <CartContext.Consumer>
    {({ checkoutId }) => (
      <Mutation mutation={REMOVE_LINE_ITEM}>
        {removeLineItem => (
          <DeleteText>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();

                checkoutId &&
                  removeLineItem({
                    variables: {
                      checkoutId,
                      lineItemId: id,
                    },
                    optimisticResponse: optimisticRemoveLineItem(
                      fullCheckoutData,
                      id
                    ),
                  });
              }}
            >
              Delete
            </a>
          </DeleteText>
        )}
      </Mutation>
    )}
  </CartContext.Consumer>
);
