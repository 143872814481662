import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Icon from './Icon';

const IconLink = styled(Link)`
  display: inline-block;
  margin-top: -2px;
  padding: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;

  :hover,
  :focus {
    opacity: 0.8;
  }
`;

export default ({ type, ...props }) => (
  <IconLink {...props}>
    <Icon type={type} />
  </IconLink>
);
