import React from 'react';
import Helmet from 'react-helmet';

export default ({ title, description, robots, canonical, image }) => (
  <Helmet>
    {title && <title>{title}</title>}

    {title && <meta property="og:title" content={title} />}

    {description && <meta name="description" content={description} />}

    {description && <meta property="og:description" content={description} />}

    {canonical && <link rel="canonical" href={canonical} />}

    {robots === 'noindex' && <meta name="robots" content="noindex" />}

    {image && <meta property="og:image" content={image} />}
  </Helmet>
);
